@import "./vars.scss";

*
{
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    font-family: 'Oswald';
    color: $text-color;
    font-weight: 300;
    outline: none;
}

.scroll
{
	overflow-y: overlay;
	overflow-x: hidden;
}

.scroll::-webkit-scrollbar {
	background-color: transparent;
}

.scroll:hover::-webkit-scrollbar {
	background-color: rgba(149, 149, 149, 0.1);
}

.scroll::-webkit-scrollbar-thumb {
	background-color: transparent;
}

.scroll:hover::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.5);
}

.center
{
    display: flex;
    align-items: center;
    justify-content: center !important;
    width: 100%;
}

.title
{
    font-size: 30pt;
    font-weight: 300;
    color: white;
    margin-bottom: 20px;
}

.sub-title
{
    display: flex;
    align-items: center;
    height: 50px;
    border-left: 6px solid $verde;
    font-size: 18pt;
    font-weight: 300;
    color: white;
    padding: 0px 0px 0px 10px;
    background-color: rgba(darken($content-background-color, 2%), 0.9);
    margin-bottom: 20px;
}

.description
{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.description > p
{
    font-size: 15pt;
    text-align: justify;
    font-weight: 300;
    margin-bottom: 20px;
}

.description > img
{
    width: 200px;
    height: 200px;
    margin-left: 20px;
    border-radius: 5px;
}

.section > p
{
    text-align: justify;
    font-size: 14pt;
    font-weight: 300;
    margin-bottom: 20px;
}

.display-block
{
    display: block;
}

.display-none
{
    display: none;
}

@media all and (max-width: 1000px) 
{

    .title
    {
        font-size: 20pt;
        font-weight: 300;
        color: white;
    }

    .sub-title
    {
        border-left: 4px solid $verde;
        font-size: 15pt;
        font-weight: 300;
        color: white;
        padding: 5px;
        padding-left: 10px;
    }

    .description > p
    {
        font-size: 13pt !important;
    }

    .section > p
    {
        font-size: 12pt;
    }

}

.rotate
{
    animation: spin 1s infinite linear;
}

@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}