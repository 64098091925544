@import "./vars.scss";

.modal {
  position: fixed;
  z-index: 999;
  top: 0px;
  left: 0px;
  width: 100vw;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  display: none;
  justify-content: center;
  align-items: center;
}

.modal.is-open {
  display: flex;
}

.modal-container {
  position: relative;
  padding: 1rem;
  width: 50vw;
  max-height: 80vh;
  background-color: darken($nav-background-color, 5%);
  border-radius: 5px;
}

.modal-container > h3 {
  display: flex;
  align-items: center;
  font-weight: 300;
}

.modal-container > h3 svg {
  margin-right: 10px;
}

.modal-container > div {
  padding: 20px;
  margin-top: 20px;
  text-align: justify;
  max-height: calc(80vh - 40px);
}

.modal-container > .modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  border: 0px;
  background-color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  cursor: pointer;
}

.modal-container > .modal-close:hover {
  color: white;
}

@media all and (max-width: 1000px) {

  .modal-container {
    padding: 1rem;
    width: 90vw;
    min-height: 50vh;
    max-height: 90vh;
  }
  
}
