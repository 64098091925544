@import "./vars.scss";

.menu
{
    grid-area: menu;
    display: flex;
    justify-content: center;
    align-items: center;
}

ul
{
    list-style-type: none;
}

ul li a
{
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 14pt;
}

ul li a span
{
    font-weight: 300;
}

ul li:not(:last-child)
{
    margin-bottom: 10px;
}

ul li a:hover *
{
    color: white;
}

ul li a span
{
    margin-left: 10px;
}

@media all and (max-width: 1000px) 
{

    .menu
    {
        position: fixed;
        top: 70px;
        right: 0px;
        height: calc(100vh - 70px);
        width: 200px;
        background-color: rgba(darken($nav-background-color, 10%), 0.9);
        transition: 0.3s ease-in-out;
        z-index: 999;
    }

    .menu.hide
    {
        right: -200px;
    }

    .menu.show
    {
        right: 0px;
    }

    .menu > ul li
    {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .menu > ul li a
    {
        font-size: 14pt;
    }

}