@import "./vars.scss";

.stack-type
{
    display: grid;
	grid-template-areas: "stack-type-title stack-type-detail";
    grid-template-columns: 130px auto;
    width: 100%;    
    background-color: darken($content-background-color, 2%);
    border-radius: 5px;
    border: 1px solid #151515;
}

.stack-type:not(:last-child)
{
    margin-bottom: 5px;
}

.stack-type:last-child
{
    margin-bottom: 20px;
}

.stack-type > .stack-type-title
{
    grid-area: stack-type-title;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: darken($content-background-color, 3%);
    border-radius: 5px 0px 0px 5px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.stack-type > .stack-type-title > i
{
    font-size: 25pt;
    color: white;
}

.stack-type > .stack-type-title > span
{
    font-size: 12pt;
    margin-top: 5px;
    color: white;
    font-weight: 300;
}

.stack-type > .stack-type-detail
{
    grid-area: stack-type-detail;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: lighten($content-background-color, 15%);
    border-radius: 0px 5px 5px 0px;
    padding: 10px;
}

.stack-type > .stack-type-detail > .stack-list
{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

@media all and (max-width: 1000px) 
{

    .stack-type
    {
        display: grid;
        grid-template-areas: "stack-type-title"
                             "stack-type-detail";
        grid-template-columns: auto;
    }

    .stack-type > .stack-type-title
    {
        grid-area: stack-type-title;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 5px 5px 0px 0px;
    }

    .stack-type > .stack-type-title > i
    {
        font-size: 20pt;
    }

    .stack-type > .stack-type-title > span
    {
        font-size: 14pt;
        margin-left: 10px;
    }

    .stack-type > .stack-type-detail
    {
        grid-area: stack-type-detail;
        padding: 10px;
    }

}