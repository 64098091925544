@import "./vars.scss";

.content
{
    grid-area: content;
    background-color: $content-background-color;
    background-image: url(../images/about.png);
    background-size: cover;
    padding: 40px;
}

@media all and (max-width: 1000px) 
{

    .content
    {
        padding: 20px;
    }

}