@import "./vars.scss";

// background-color: darken($content-background-color, 1%);

.contact > .body
{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: darken($content-background-color, 1%);
    padding: 40px;
    border-radius: 5px;
}

.contact > .body > .form
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 540px;
}

.contact > .body > .form > .field
{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px;
}

.contact > .body > .form > .field > label
{
    width: 140px;
    font-size: 14pt;
    color: white;
    font-weight: 300;
}

.contact > .body > .form > .field > input,
.contact > .body > .form > .field > textarea
{
    border: 0px;
    font-size: 14pt;
    padding-left: 10px;
    color: white;
    height: 40px;
    resize: none;
    border-radius: 5px;
}

.contact > .body > .form > .field > input.normal,
.contact > .body > .form > .field > textarea.normal
{
    background-color: lighten($content-background-color, 10%)
}

.contact > .body > .form > .field > input.error,
.contact > .body > .form > .field > textarea.error
{
    background-color: 	#FAA0A0
}

.contact > .body > .form > .field > input#txtName,
.contact > .body > .form > .field > input#txtEmail
{
    width: 400px;
}

.contact > .body > .form > .field > input#txtPhone
{
    width: 200px;
}

.contact > .body > .form > .field > textarea#txtMessage
{
    padding: 10px;
    width: 400px;
    height: 80px;
}

.contact > .body > .form > .field > input:focus,
.contact > .body > .form > .field > textarea:focus
{
    background-color: lighten($content-background-color, 20%);
}

.contact > .body > .form > .field > button
{
    border: 0px;
    font-size: 14pt;
    font-weight: 300;
    padding: 5px;
    background-color: $verde;
    width: 150px;
    border-radius: 5px;
    height: 40px;
}

.contact > .body > .form > .field > button *
{
    color: white;
}

.contact > .body > .form > .field > button span
{
    margin-left: 10px;
}

.contact > .body > .form > .field > button:hover
{
    background-color: darken($verde, 10%);
}

@media all and (max-width: 1000px) 
{

    .contact > .body
    {
        padding: 20px;
        border-radius: 5px;
    }

    .contact > .body > .form
    {
        width: 100%;
    }

    .contact > .body > .form > .field
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
    }

    .contact > .body > .form > .field > label
    {
        align-items: flex-start;
        width: 100%;
    }

    .contact > .body > .form > .field > input#txtName,
    .contact > .body > .form > .field > input#txtEmail,
    .contact > .body > .form > .field > input#txtPhone,
    .contact > .body > .form > .field > textarea#txtMessage,
    .contact > .body > .form > .field > button
    {
        width: 100%;
    }

}
