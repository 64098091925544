@import "./vars.scss";

.about
{
	width: 100%;
	height: 100%;
}

.about > .section > .steps
{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    margin-bottom: 20px;
}

.about > .section > .steps > .steps-item
{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-weight: 300;
    margin-left: 5px;
    margin-right: 5px;
    border: 1px solid darken($gris, 30%);
}

.about > .section > .steps > .steps-item > div:first-child
{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: darken($content-background-color, 3%);
    width: 40px;
    height: 60px;
    color: white;
    font-size: 20pt;
    border-radius: 5px 0px 0px 5px;
}

.about > .section > .steps > .steps-item > div:last-child
{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: lighten($content-background-color, 15%);
    width: 140px;
    height: 60px;
    border-radius: 0px 5px 5px 0px;
    color: white;
    font-weight: 300;
}

@media all and (max-width: 1000px) 
{

    .about > .description > p
    {
        font-size: 14pt;
    }

    .about > .description > img
    {
        display: none;
    }

}