@import "./vars.scss";

.portfolio
{
	display: grid;
	grid-template-areas: "nav content";
	grid-template-columns: 250px auto;
	grid-template-rows: auto;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
}

@media all and (max-width: 1000px) 
{

	.portfolio
	{
		grid-template-areas: "nav"
		                     "content";
		grid-template-rows: 70px auto;
		grid-template-columns: auto;
	}

}