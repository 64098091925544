@import "./vars.scss";

.photo
{
    grid-area: photo;
    display: grid;
	grid-template-areas: "name"
                         "image"
                         "mini-description"
                         "network"
                         "ham-button";
	grid-template-rows: 60px auto 80px 60px 0px;
    padding: 20px;
}

.photo > .name
{
    grid-area: name;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 18pt;
    color: white;
    font-weight: 300;
}

.photo > .image
{
    grid-area: image;
    display: flex;
    justify-content: center;
    align-items: center;
}

.photo > .image img
{
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.photo > .mini-description
{
    grid-area: mini-description;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0px 10px 0px 10px;
    font-weight: 300;
}

.photo > .network
{
    grid-area: network;
    display: flex;
    justify-content: center;
    align-items: center;
}

.photo > .network .network-item
{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    background-color: $button-background-color;
    border-radius: 50%;
    cursor: pointer;
}

.photo > .network .network-item:hover
{
    background-color: $button-background-color-hover;
}

.photo > .network .network-item:not(:last-child)
{
    margin-right: 5px;
}

.photo > .network .network-item *
{
    color: white;
}

@media all and (max-width: 1000px) 
{

	.photo
	{
        grid-template-areas: "image name";
        grid-template-columns: 70px auto;
        width: 100%;
        padding: 5px;
	}

    .photo > .mini-description
    {
        display: none;
    }

    .photo > .network
    {
        display: none;
    }

    .photo > .image img
    {
        width: 60px;
        height: 60px;
    }

    .photo > .name
    {
        font-size: 15pt;
    }

}