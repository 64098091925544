@import "./vars.scss";

.nav
{
    grid-area: nav;
    display: grid;
	grid-template-areas: "photo"
                         "menu"
                         "language"
						 "ham-button";
	grid-template-rows: 350px auto 50px 0px;
	width: 100%;
	height: 100%;
	background-color: $nav-background-color;
}

.nav > .ham-button
{
    grid-area: ham-button;
    display: none
}

@media all and (max-width: 1000px) 
{

	.nav
	{
        grid-template-areas: "photo ham-button";
        grid-template-columns: auto 70px;
		width: 100%;
		border-bottom: 1px solid $gris;
	}

	.nav > .language
	{
		display: none;
	}

	.nav > .ham-button
	{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 70px;
		height: 70px;
		cursor: pointer;
	}

	.nav > .ham-button > button
	{
		background-color: transparent;
		border: 0px;
	}

	.nav > .ham-button > button > i
	{
		color: white;
		font-size: 20pt;
	}

}