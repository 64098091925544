@import "./vars.scss";

.project-stack
{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.projects .section h3
{
    font-size: 15pt;
    color: white;
    font-weight: 400;
    margin-bottom: 10px;
}

.projects .section ul
{
    list-style-type: square;
    padding-left: 25px;
    margin-bottom: 20px;
    line-height: 1.2em;
}

.projects .section ul li
{
    font-size: 14pt;
    font-weight: 300;
}

@media all and (max-width: 1000px) 
{



}