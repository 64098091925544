@import "./vars.scss";

.resume
{
    display: grid;
	grid-template-areas: "content personal";
	grid-template-columns: auto 350px;
    width: 100%;
}

    .resume > .rs-content
    {
        grid-area: content;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        background-color: white;
        padding: 20px;
        padding-left: 30px;
        padding-right: 30px;
        width: 100%;
    }

    .resume > .rs-content *
    {
        color: darken($gris, 40%);
        font-weight: 300;
    }

        .resume > .rs-content > .rs-section
        {
            width: 100%;
        }

            .resume > .rs-content > .rs-section > .rs-title
            {
                font-size: 30pt;
                color: black;
                margin-bottom: 20px;
            }

            .resume > .rs-content > .rs-section > .rs-description
            {
                font-size: 13pt;
                text-align: justify;
                line-height: 1.1em;
            }

            .resume > .rs-content > .rs-section > .rs-sub-title
            {
                margin-top: 20px;
                font-size: 18pt;
                color: black;
                border-bottom: 1px solid black;
                width: 100%;
            }

            .resume > .rs-content > .rs-section > .rs-detail > .rs-item
            {
                display: grid;
                grid-template-areas: "rs-range rs-circle rs-job";
                grid-template-columns: 50px 50px auto;
            }

                .resume > .rs-content > .rs-section > .rs-detail
                {
                    margin-top: 20px;
                }

                .resume > .rs-content > .rs-section > .rs-detail > .rs-item > .rs-range
                {
                    grid-area: rs-range;
                    height: 100%;
                }

                    .resume > .rs-content > .rs-section > .rs-detail > .rs-item > .rs-range > .rs-range-item
                    {
                        background-color: darken($verde, 10%);
                        color: white;
                        margin-top: 2px;
                        text-align: center;
                        font-weight: 400;
                        width: 100%;
                        height: 25px;
                    }

                .resume > .rs-content > .rs-section > .rs-detail > .rs-item > .rs-circle
                {
                    grid-area: rs-circle;
                    display: grid;
                    grid-template-areas: "rs-center-circle rs-center-circle"
                                         "rs-line-left     rs-line-right";
                    grid-template-columns: 25px 25px;
                    grid-template-rows: 25px auto;
                    height: 100%;
                }

                    .resume > .rs-content > .rs-section > .rs-detail > .rs-item > .rs-circle > .rs-center-circle
                    {
                        grid-area: rs-center-circle;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                        .resume > .rs-content > .rs-section > .rs-detail > .rs-item > .rs-circle > .rs-center-circle > .rs-dot
                        {
                            width: 25px;
                            height: 25px;
                            background-color: $gris;
                            border-radius: 50%;                            
                        }
                    
                    .resume > .rs-content > .rs-section > .rs-detail > .rs-item > .rs-circle > .rs-line-left
                    {
                        grid-area: rs-line-left;
                        border-right: 1px solid $gris;
                    }
                    
                    .resume > .rs-content > .rs-section > .rs-detail > .rs-item > .rs-circle > .rs-line-right
                    {
                        grid-area: rs-line-right;
                        border-left: 1px solid $gris;
                    }

                .resume > .rs-content > .rs-section > .rs-detail > .rs-item > .rs-job
                {
                    grid-area: rs-job;
                    height: 100%;
                }

                    .resume > .rs-content > .rs-section > .rs-detail > .rs-item > .rs-job > .rs-company
                    {
                        font-size: 14pt;
                        font-weight: 400;
                        color: black;
                    }

                    .resume > .rs-content > .rs-section > .rs-detail > .rs-item > .rs-job > .rs-position
                    {
                        font-size: 14pt;
                        font-weight: 400;
                        color: darken($verde, 10%);
                    }

                    .resume > .rs-content > .rs-section > .rs-detail > .rs-item > .rs-job > .rs-tasks
                    {
                        text-align: justify;
                        font-size: 13pt;
                        line-height: 1.2em;
                        margin-bottom: 20px;
                    }

    .resume > .rs-personal
    {
        grid-area: personal;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        background-color: darken($verde, 10%);
    }

        .resume > .rs-personal *
        {
            color: white;
            font-weight: 300;
        }

        .resume > .rs-personal > .rs-photo
        {
            padding: 0px;
            height: 350px;
        }

        .resume > .rs-personal > .rs-photo img
        {
            max-width: 350px;
            max-height: 350px;
        }

        .resume > .rs-personal > .rs-name
        {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
            width: 100%;
        }

            .resume > .rs-personal > .rs-name > .rs-h1
            {
                text-align: center;
                font-size: 30pt;
                font-weight: 300;
                width: 100%;
                line-height: 1.3em;
            }

            .resume > .rs-personal > .rs-name > .rs-h2
            {
                text-align: center;
                font-size: 17pt;
                font-weight: 300;
                width: 100%;
                line-height: 1.2em;
                margin-bottom: 20px;
            }

        .resume > .rs-personal > .rs-section
        {
            margin-top: 20px;
            padding-left: 25px;
            padding-right: 25px;
            width: 100%;
        }

            .resume > .rs-personal > .rs-section > .rs-form
            {
                border-bottom: 1px solid white;
            }

                .resume > .rs-personal > .rs-section > .rs-form > .rs-dato
                {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }

                .resume > .rs-personal > .rs-section > .rs-form > .rs-dato:first-child
                {
                    margin-top: 10px;
                }

                .resume > .rs-personal > .rs-section > .rs-form > .rs-dato:last-child
                {
                    margin-bottom: 10px;
                }

                    .resume > .rs-personal > .rs-section > .rs-form > .rs-dato > .rs-nombre
                    {
                        font-weight: 400;
                        width: 110px;
                    }

        .resume > .rs-personal > .rs-section > .rs-title
        {
            font-size: 18pt;
            font-weight: 400;
            border-bottom: 1px solid white;
            width: 100%;
        }

        .resume > .rs-personal > .rs-section > .rs-list
        {
            border-bottom: 1px solid white;
        }

            .resume > .rs-personal > .rs-section > .rs-list ul
            {
                margin-top: 10px;
                margin-bottom: 10px;
                list-style-type: square;
                padding-left: 25px;
            }

                .resume > .rs-personal > .rs-section > .rs-list ul li
                {
                    margin: 0px;
                    font-size: 14pt;
                }

        .resume > .rs-personal > .rs-section > .rs-version
        {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
        }
    
@media all and (max-width: 1000px) 
{

    .resume
    {
        grid-template-areas: "personal"
                             "content";
        grid-template-columns: auto auto;
        width: calc(100vw - 40px);
    }    

    .resume > .rs-personal > .rs-photo
    {
        display: none
    }

}