@import "./vars.scss";

.stack-item
{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: darken($nav-background-color, 5%);
    padding: 10px;
    width: 140px;
    min-width: 140px;
    height: 40px;
    border-radius: 5px;
    margin: 1px;
    border: 1px solid darken($gris, 30%);
}

.stack-item.stack-item-select
{
    cursor: pointer;
}

.stack-item.stack-item-select:hover
{
    background-color: darken($nav-background-color, 30%);
}

.stack-item > span
{
    margin-left: 10px;
    color: white;
    font-size: 12pt;
    font-weight: 300;
}